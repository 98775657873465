import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export async function validateRecaptcha(
  recaptchaToken: string,
  scoreValidator = 0.4
) {
  const respGoogleCapcha = await fetch(
    `https://www.google.com/recaptcha/api/siteverify`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `secret=${process.env
        .GOOGLE_RECAPTCHA_SECRET!}&response=${recaptchaToken}`
    }
  )
  const respParsed = await respGoogleCapcha.json()

  if (!respParsed.success || respParsed.score < scoreValidator) {
    return false
  }
  return true
}

export default function useRecaptcha(
  onResponse: CallbackWithParam<string>,
  action: string
) {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha(action)
    return await onResponse(token)
    // Do whatever you want with the token
  }, [action, executeRecaptcha, onResponse])

  return { handleReCaptchaVerify }
}
