import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import React, {
  KeyboardEvent,
  InputHTMLAttributes,
  useRef,
  useEffect
} from 'react'
import { twMerge } from 'tailwind-merge'
import FieldLabel, { AdditionalFieldLabelProps } from './FieldLabel'
import Typography from './Typography'

export interface IProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string
  preLabel?: React.ReactNode
  postLabel?: React.ReactNode
  icon?: IconProp
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  labelProps?: Partial<AdditionalFieldLabelProps>
  placeholder?: string
  layout?: 'horizantal' | 'vertical'
  className?: string
  inputClassName?: string
  containerClassName?: string
  labelClassName?: string
  preLabelClassName?: string
  postLabelClassName?: string
  iconClassName?: string
  dividerClassName?: string
  errorClassName?: string
  color?: 'gray' | 'white' | 'transparent'
  outlined?: boolean
  required?: boolean
  onEnter?: Callback
  error?: string | boolean
  minRows?: number
  disabled?: boolean
  iconDivider?: boolean
  type?: string
  horizantalFullWidth?: boolean
  postContent?: React.ReactElement | false | ''
  disableFocusBorder?: boolean
  as?: 'input' | 'span'
}

const TextField = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      label,
      preLabel,
      postLabel,
      icon,
      iconProps,
      labelProps,
      layout = 'vertical',
      className,
      inputClassName,
      containerClassName,
      labelClassName,
      preLabelClassName,
      postLabelClassName,
      iconClassName,
      dividerClassName,
      color = 'white',
      outlined = true,
      error,
      minRows = 4,
      errorClassName,
      required,
      iconDivider = false,
      horizantalFullWidth = false,
      postContent,
      disabled,
      disableFocusBorder = false,
      onEnter,
      as: Tag = 'input',
      ...rest
    },
    ref
  ) => {
    const isMultiple = rest.multiple
    // const hasValue = !!rest.value

    const containerClassNames = twMerge(
      'flex',
      layout === 'vertical' ? 'flex-col' : 'justify-between',
      layout === 'horizantal' && !isMultiple && 'items-center',
      horizantalFullWidth && 'w-full',
      containerClassName
    )

    // total height should be 45 = 41 + 1px x2 borders + 1px x2 padding, padding is replaced with 2px border on focus
    const inputWrapperClasses = twMerge(
      //order matters
      'flex items-center shadow-sm rounded-md min-h-[45px]',
      isMultiple && 'items-start', // so precontent is aligned to top
      !disableFocusBorder &&
        'focus-within:border-2 focus-within:border-blue-500 focus-within:p-0', // on focus, not visible on transparent

      outlined && 'border border-gray-100 p-[1px]',
      outlined && color === 'white' ? 'border-gray-300' : '',

      color === 'gray' && 'bg-gray-100',
      color === 'transparent' && 'bg-none shadow-none border-transparent',
      color === 'white' && 'bg-white',

      error && 'border border-red-500',
      // required && !hasValue && 'border border-red-500',

      layout === 'horizantal' && 'min-w-xxs',
      horizantalFullWidth && 'flex-1',
      disabled && 'bg-gray-200',
      className
    )

    const inputClasses = twMerge(
      'flex-1 text-sm text-gray-800 placeholder-gray-400 h-[calc(100%-1px)]',
      'bg-transparent border-none focus-visible:outline-0 focus:ring-0',
      !preLabel ? 'px-3' : 'px-0.5',
      inputClassName
    )

    const labelClasses = twMerge(
      layout === 'horizantal' && 'mr-2',
      labelClassName
    )

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && e.shiftKey == false) {
        onEnter?.()
      }
    }

    const inputRef = useRef<HTMLSpanElement | HTMLInputElement>(null!)
    useEffect(() => {
      if (rest.autoFocus) {
        inputRef.current?.focus?.()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <div className={containerClassNames} ref={ref}>
        {label && (
          <FieldLabel
            {...labelProps}
            className={labelClasses}
            required={required}
            label={label}
          />
        )}

        {/* TODO: Move these so they are perhaps injectable? */}
        <div className={inputWrapperClasses}>
          {icon && (
            <>
              <Typography
                size="sm"
                color="lightGray"
                className={twMerge('pl-3', isMultiple && 'my-2', iconClassName)}
              >
                <FontAwesomeIcon icon={icon} size="lg" {...iconProps} />
              </Typography>

              {iconDivider && (
                <div
                  className={twMerge(
                    'border-r border-gray-300 ml-3 min-h-[41px]',
                    dividerClassName
                  )}
                />
              )}
            </>
          )}

          {preLabel && (
            <Typography
              className={twMerge(
                'pl-3',
                isMultiple && 'my-2',
                preLabelClassName
              )}
              size="sm"
              color="lightGray"
            >
              {preLabel}
            </Typography>
          )}

          {isMultiple ? (
            <textarea
              {...rest}
              className={inputClasses}
              required={required}
              rows={minRows}
              disabled={disabled}
            />
          ) : (
            <Tag
              {...rest}
              className={inputClasses}
              required={required}
              disabled={disabled}
              onKeyDown={handleOnKeyDown}
              // @ts-ignore
              ref={inputRef}
            />
          )}

          {postLabel && (
            <Typography
              className={twMerge('pr-3', postLabelClassName)}
              size="sm"
              color="lightGray"
            >
              {postLabel}
            </Typography>
          )}

          {postContent && <div className="mr-2">{postContent}</div>}
        </div>

        {typeof error === 'string' && (
          <Typography color="red" size="xs" className={errorClassName}>
            {error}
          </Typography>
        )}
      </div>
    )
  }
)
TextField.displayName = 'TextField'

export default TextField
