import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import { useControlledInput } from 'hooks'
import fetch from 'isomorphic-unfetch'
import useRecaptcha from 'hooks/useRecaptcha'
import { useCallback, useState } from 'react'
import TextField from 'components/common/TextField'

function NewsletterSignup() {
  const [success, setSuccess] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [email, setEmail, clearEmail] = useControlledInput('')

  const onSubmit = useCallback(
    async recaptchaToken => {
      setSaving(true)
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        body: JSON.stringify({
          recaptchaToken,
          email
        })
      }).then(x => x.json())

      if (resp.success) {
        setSaving(false)
        setSuccess(true)
        setError('')
        clearEmail()
      } else {
        setSaving(false)
        setSuccess(false)
        setError(
          resp.message ||
            'Failed to signup to newsletter. Please try again later.'
        )
      }
    },
    [clearEmail, email]
  )

  const { handleReCaptchaVerify } = useRecaptcha(onSubmit, 'newsletterSignup')

  if (success) {
    return (
      <div className="mt-4 lg:mt-0 flex space-x-2 items-center">
        <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-green-100">
          <FontAwesomeIcon icon={faCheck} className="text-green-600" />
        </div>
        <p className="text-green-500 font-medium pt-2">{`Awesome! We'll be in touch soon.`}</p>
      </div>
    )
  }

  return (
    <div className="mt-4 sm:flex sm:max-w-md items-center lg:mt-0 w-full">
      <label htmlFor="emailAddress" className="sr-only">
        Email address
      </label>

      <div>
        <TextField
          type="email"
          name="emailAddress"
          id="emailAddress"
          autoComplete="email"
          required
          className="w-64"
          placeholder="sarah@upstreamapp.com"
          disabled={saving}
          value={email}
          onChange={setEmail}
        />
        {!!error && <p className="text-red-500 pt-1 text-sm">{error}</p>}
      </div>

      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:shrink-0">
        <Button
          onClick={handleReCaptchaVerify}
          label={'Subscribe'}
          type={'submit'}
          disabled={saving}
          loading={saving}
          className="text-base font-medium py-3 rounded px-6 w-full md:w-auto"
        />
      </div>
    </div>
  )
}

export default NewsletterSignup
