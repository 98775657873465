import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import NewsletterSignup from './NewsletterSignup/NewsletterSignup'

const Footer = () => (
  <footer className="bg-text-primary pt-16 pb-10 px-6 font-light space-y-8">
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 pb-6">
      <div>
        <h3 className="text-sm text-gray-300 tracking-wider font-normal uppercase">
          Discover
        </h3>

        <ul className="mt-4 space-y-3">
          <li>
            <Link
              className="text-gray-400 hover:text-white"
              href={'/collectives'}
            >
              DAOs
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/nft-dao'}>
              NFT Project DAOs
            </Link>
          </li>
          <li>
            <Link
              className="text-gray-400 hover:text-white"
              href={'/investment-dao'}
            >
              Investment Club DAOs
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/vault'}>
              Vault DAO
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/events'}>
              Events
            </Link>
          </li>
        </ul>
      </div>

      <div>
        <h3 className="text-sm text-gray-300 tracking-wider font-normal uppercase">
          Company
        </h3>

        <ul className="mt-4 space-y-3">
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/about'}>
              About
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/contact'}>
              Contact
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/team'}>
              Team
            </Link>
          </li>
          <li>
            <Link
              className="text-gray-400 hover:text-white"
              href={'https://blog.upstreamapp.com'}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              className="text-gray-400 hover:text-white"
              href={'https://guide.upstreamapp.com'}
            >
              Help Center
            </Link>
          </li>
        </ul>
      </div>

      <div>
        <h3 className="text-sm text-gray-300 tracking-wider font-normal uppercase">
          Legal
        </h3>

        <ul className="mt-4 space-y-3">
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/privacy'}>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link className="text-gray-400 hover:text-white" href={'/terms'}>
              Terms
            </Link>
          </li>
          <li>
            <Link
              className="text-gray-400 hover:text-white"
              href={'/guidelines'}
            >
              Community Guidelines
            </Link>
          </li>
        </ul>
      </div>
    </div>

    <div className="pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0 max-w-5xl">
      <div>
        <h3 className="text-sm text-gray-300 tracking-wider uppercase font-normal">
          Stay in-touch
        </h3>
        <p className="mt-2 text-base text-gray-400">
          Want to stay up-to-date on our latest announcements and products?
        </p>
      </div>

      <NewsletterSignup />
    </div>

    <div className="border-t border-gray-700 pt-6 flex flex-col md:flex-row items-center justify-between font-light pb-6">
      <p className="text-gray-400 text-sm pt-4 md:pt-0 ">
        &copy; {new Date().getFullYear()} Copyright Modern Mast Corporation
      </p>

      <div className="flex space-x-6 ">
        <a
          href={`https://twitter.com/joinupstream`}
          className="text-gray-400 hover:text-gray-300"
        >
          <FontAwesomeIcon icon={faTwitter} size={'lg'} />
        </a>
        <a
          href={`https://www.facebook.com/joinupstream`}
          className="text-gray-400 hover:text-gray-300"
        >
          <FontAwesomeIcon icon={faFacebook} size={'lg'} />
        </a>
        <a
          href={`https://www.linkedin.com/company/30751334`}
          className="text-gray-400 hover:text-gray-300"
        >
          <FontAwesomeIcon icon={faLinkedin} size={'lg'} />
        </a>
        <a
          href={`https://www.instagram.com/joinupstream/`}
          className="text-gray-400 hover:text-gray-300"
        >
          <FontAwesomeIcon icon={faInstagram} size={'lg'} />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
